import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

export default () => {
  const { t } = useTranslation();
  return (
    <Container className="container-space article-text">
      <Row>
        <Col>
          <h2 className="text-blue">
            {t('WhyDoYouNeedQualityManagementSystemArticleTitle2')}
          </h2>
          <p className="pb-4">
            {t('WhyDoYouNeedQualityManagementSystemArticlePara2')}
          </p>
          <h2 className="text-blue">
            {t('WhyDoYouNeedQualityManagementSystemArticleTitle3')}
          </h2>
          <p className="pb-4">
            {t('WhyDoYouNeedQualityManagementSystemArticlePara3')}
          </p>
          <h2 className="text-blue">
            {t('WhyDoYouNeedQualityManagementSystemArticleTitle4')}
          </h2>
          <p className="pb-4">
            {t('WhyDoYouNeedQualityManagementSystemArticlePara4')}
          </p>
          <h2 className="text-blue">
            {t('WhyDoYouNeedQualityManagementSystemArticleTitle5')}
          </h2>
          <p className="pb-4">
            {t('WhyDoYouNeedQualityManagementSystemArticlePara5')}
          </p>
          <h2 className="text-blue">
            {t('WhyDoYouNeedQualityManagementSystemArticleTitle6')}
          </h2>
          <p className="pb-4">
            {t('WhyDoYouNeedQualityManagementSystemArticlePara6')}
          </p>
          <h2 className="text-blue">
            {t('WhyDoYouNeedQualityManagementSystemArticleTitle7')}
          </h2>
          <p className="pb-4">
            {t('WhyDoYouNeedQualityManagementSystemArticlePara7')}
          </p>
          <h2 className="text-blue">
            {t('WhyDoYouNeedQualityManagementSystemArticleTitle8')}
          </h2>
          <p className="pb-4">
            {t('WhyDoYouNeedQualityManagementSystemArticlePara8')}
          </p>
          <h2 className="text-blue">
            {t('WhyDoYouNeedQualityManagementSystemArticleTitle9')}
          </h2>
          <p className="pb-4">
            {t('WhyDoYouNeedQualityManagementSystemArticlePara9')}
          </p>
          <h2 className="text-blue">
            {t('WhyDoYouNeedQualityManagementSystemArticleTitle10')}
          </h2>
          <p>{t('WhyDoYouNeedQualityManagementSystemArticlePara10')}</p>
        </Col>
      </Row>
    </Container>
  );
};
